<template>
  <section class="new-invoice">
    <div class="msg" :class="{'error': error}" v-if="msg">
      <span>{{msg}}</span>
      <button @click="clearMessage">x</button>
    </div>
    <h1>Ny faktura <small>Fy fan vad kul!</small></h1>

    <section class="invoice-settings">
      <fieldset>
        <label>Välj kund (eller <router-link to="/new-client">skapa ny</router-link>)</label>
        <select ref="clientSelector" @change="updateClientContact">
          <option>-- Välj kund</option>
          <option v-for="client in clients" :key="`client-${client.id}`" :value="client.id">
            {{client.name}}
          </option>
        </select>
      </fieldset>

      <fieldset>
        <label>Välj valuta</label>
        <select v-model="currency">
          <option value="SEK">SEK</option>
          <option value="EUR">EUR</option>
          <option value="USD">USD</option>
        </select>
      </fieldset>

      <fieldset>
        <label>Välj momssats</label>
        <select v-model="vatPercent">
          <option value="25">25 %</option>
          <option value="0">0 %</option>
        </select>
      </fieldset>
    </section>

    <section class="invoice-rows">
      <h2>Fakturaposter</h2>

      <table border="0">
        <thead>
        <tr>
          <th>Text</th>
          <th>Belopp exkl. moms</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="i in invoiceRows" :key="`row-${i}`">
          <td>
            <input type="text" placeholder="Lyckohjul" data-type="invoiceRowText" />
          </td>
          <td>
            <input type="number" data-type="invoiceRowAmount" placeholder="Belopp exkl. moms"
                   @keyup="updateInvoiceAmount" />
          </td>
        </tr>
        </tbody>
      </table>
      <button @click="addInvoiceRow">Lägg till ny rad</button>
    </section>

    <section class="invoice-moneyinfo">
      <div>Totalt exkl. moms: {{formatNumber(invoiceAmount)}}</div>
      <div>Moms ({{vatPercent}} %): {{formatNumber((vatPercent / 100) * invoiceAmount)}}</div>
      <div>Totalt inkl. moms: {{formatNumber(invoiceAmount * ((vatPercent / 100) + 1))}}</div>
    </section>

    <section class="invoice-details">
      <h2>Fakturainformation</h2>
      <fieldset>
        <label>Nummer</label>
        <input type="number" v-model="invoiceNumber" />
      </fieldset>
      <fieldset>
        <label>Fakturadatum</label>
        <input type="date" v-model="invoiceDate" />
      </fieldset>
      <fieldset>
        <label>Betalningsvillkor</label>
        <input type="number" v-model="termDays" />
      </fieldset>
      <fieldset>
        <label>Förfallodatum</label>
        <input type="date" disabled v-model="dueDate" />
      </fieldset>
      <fieldset>
        <label>Vår kontakt</label>
        <input type="text" v-model="ourContact" />
      </fieldset>
      <fieldset>
        <label>Deras kontakt</label>
        <input type="text" v-model="theirContact" />
      </fieldset>
      <fieldset>
        <label>Projektnamn (kundens)</label>
        <input type="text" v-model="clientProjectName" />
      </fieldset>
      <fieldset>
        <label>Projektnamn (för internt bruk)</label>
        <input type="text" v-model="internalProjectName" />
      </fieldset>

      <button @click="createInvoice" :disabled="selectedClientId === 0">Skapa faktura</button>

      <router-link :to="invoiceURL" v-if="pdfBtnEnabled">Skriv ut PDF</router-link>
    </section>
  </section>
</template>

<script>
import {
  collection,
  doc,
  getFirestore,
  getDoc,
  getDocs,
  setDoc,
  query,
  orderBy,
  limit,
} from 'firebase/firestore';

export default {
  name: 'NewInvoice',
  data() {
    return {
      db: getFirestore(),
      invoiceNumber: 1000,
      msg: '',
      error: false,
      currency: 'SEK',
      invoiceRows: 4,
      vatPercent: 25,
      invoiceAmount: 0,
      termDays: 30,
      invoiceDate: new Date().toISOString().slice(0, 10),
      ourContact: 'Farid Al-Ghussein',
      clientProjectName: '',
      theirContact: '',
      internalProjectName: '',
      pdfBtnEnabled: false,
      invoiceURL: '',
      clients: [],
      selectedClientId: 0,
    };
  },
  mounted() {
    this.getInvoices();
    this.getClientsList();

    document.title = 'Ny faktura';
  },
  computed: {
    dueDate() {
      const newDueDate = new Date(this.invoiceDate);
      newDueDate.setDate(newDueDate.getDate() + this.termDays);
      // eslint-disable-next-line max-len,vue/max-len
      return `${newDueDate.getFullYear()}-${String(newDueDate.getMonth() + 1).padStart(2, '0')}-${String(newDueDate.getDate()).padStart(2, '0')}`;
    },
  },
  methods: {
    async getClientsList() {
      const q = query(collection(this.db, 'clients'), orderBy('clientName', 'asc'));
      const querySnapshot = await getDocs(q);
      querySnapshot.docs.forEach((clientDoc) => {
        const clientData = clientDoc.data();
        this.clients.push({
          name: clientData.clientName,
          contact: clientData.contact,
          vat: clientData.vat,
          clientProjectName: clientData.clientProjectName || '',
          id: clientDoc.id,
        });
      });
    },
    async getInvoices() {
      const q = query(collection(this.db, 'invoices'), orderBy('invoiceNumber', 'desc'), limit(1));
      const querySnapshot = await getDocs(q);
      if (querySnapshot.docs.length > 0) {
        this.invoiceNumber = Number(querySnapshot.docs[0].id) + 1;
      }
    },
    formatNumber(number) {
      return number.toLocaleString('sv');
    },
    addInvoiceRow() {
      this.invoiceRows += 1;
    },
    updateClientContact() {
      this.selectedClientId = Number(this.$refs.clientSelector.value);
      const clientData = this.clients.filter((item) => Number(item.id) === this.selectedClientId);
      if (clientData.length === 1) {
        this.theirContact = clientData[0].contact;
        this.clientProjectName = clientData[0].clientProjectName;
      }
    },
    updateInvoiceAmount() {
      const invoiceRows = [...document.querySelectorAll('input[data-type="invoiceRowAmount"]')];
      this.invoiceAmount = invoiceRows.reduce((prev, current) => prev + Number(current.value), 0);
    },
    /**
     * Clear the error message
     */
    clearMessage() {
      this.msg = '';
      this.error = false;
    },
    async createInvoice() {
      if (this.selectedClientId === 0) {
        return;
      }

      console.log('Create invoice');

      // Check if invoice exists
      const newInvoice = doc(this.db, 'invoices', String(this.invoiceNumber));

      const docSnap = await getDoc(newInvoice);
      if (docSnap.exists()) {
        this.msg = 'Fakturanumret finns redan.';
        this.error = true;
        return;
      }

      // Collect invoice rows
      const invoiceRowTexts = [...document.querySelectorAll('input[data-type="invoiceRowText"]')];
      const invoiceRowAmounts = [...document.querySelectorAll('input[data-type="invoiceRowAmount"]')];
      const invoiceRows = [];
      let invoiceTotal = 0;
      invoiceRowTexts.forEach((item, index) => {
        if (item.value !== '') {
          invoiceRows.push({
            text: item.value,
            amount: invoiceRowAmounts[index].value,
          });
          invoiceTotal += Number(invoiceRowAmounts[index].value);
        }
      });

      setDoc(newInvoice, {
        invoiceNumber: this.invoiceNumber,
        client: this.selectedClientId,
        currency: this.currency,
        vatPercent: this.vatPercent,
        invoiceTotal,
        invoiceRows,
        invoiceDate: this.invoiceDate,
        termDays: this.termDays,
        ourContact: this.ourContact,
        theirContact: this.theirContact,
        internalProjectName: this.internalProjectName,
        clientProjectName: this.clientProjectName,
        credited: false,
        isCreditInvoice: false,
        creditNumber: 0,
        dueDate: this.dueDate,
        paid: false,
      }, { merge: true })
        .then(() => {
          this.msg = 'Faktura skapad!';
          this.error = false;
          this.pdfBtnEnabled = true;
          this.invoiceURL = `/invoice/${this.invoiceNumber}`;
          this.invoiceNumber += 1;
          setInterval(this.clearMessage, 3000);
          console.log('Invoice inserted!');
        })
        .catch((error) => {
          this.msg = `Kunde inte skapa faktura! ${error}`;
          this.error = true;
          this.pdfBtnEnabled = false;
          console.error('Could not insert invoice', error);
        });
    },
  },
};
</script>
