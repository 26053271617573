import { createRouter, createWebHistory } from 'vue-router';
import NewInvoice from '@/views/NewInvoice.vue';

const routes = [
  {
    path: '/',
    name: 'NewInvoice',
    component: NewInvoice,
  },
  {
    path: '/new-client',
    name: 'NewClient',
    component: () => import(/* webpackChunkName: "newclient" */ '../views/NewClient.vue'),
  },
  {
    path: '/client/:id',
    name: 'EditClient',
    component: () => import(/* webpackChunkName: "editclient" */ '../views/Clients/Edit.vue'),
  },
  {
    path: '/sent-invoices',
    name: 'SentInvoices',
    component: () => import(/* webpackChunkName: "sentinvoices" */ '../views/SentInvoices.vue'),
  },
  {
    path: '/invoice/:id',
    name: 'SingleInvoice',
    component: () => import(/* webpackChunkName: "singleinvoice" */ '../views/Invoices/SingleInvoice.vue'),
  },
  {
    path: '/invoice/import',
    name: 'ImportInvoice',
    component: () => import(/* webpackChunkName: "importinvoice" */ '../views/Invoices/ImportInvoice.vue'),
  },
  {
    path: '/all-clients',
    name: 'AllClients',
    component: () => import(/* webpackChunkName: "allclients" */ '../views/AllClients.vue'),
  },
  {
    path: '/recurring-invoices',
    name: 'RecurringInvoices',
    component: () => import(/* webpackChunkName: "recurringinvoices" */ '../views/RecurringInvoices.vue'),
  },
  {
    // will match everything
    path: '/:pathMatch(.*)*',
    name: 'ErrorPage',
    component: () => import(/* webpackChunkName: "Error404" */ '../views/404.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
