<template>
  <main>

    <template v-if="loading">
      <div>Laddar…</div>
    </template>
    <template v-else>
      <div v-if="error" class="message-error">
        {{ error }}
      </div>

      <div v-if="!user">
        <h1>Här var det fakturor!</h1>
        <p>Ojdå, du är visst utloggad. Bäst att logga in…</p>
        <button class="plain" @click="login">Logga in via Google</button>
      </div>
      <div v-else>
        <nav class="main-navigation">
          <ul>
            <li><router-link to="/">💸 Ny faktura</router-link></li>
            <li><router-link to="/sent-invoices">💎 Skickade</router-link></li>
            <li><router-link to="/new-client">😎 Ny kund</router-link></li>
            <li><router-link to="/all-clients">👨‍✈️ Alla kunder</router-link></li>
            <li><router-link to="/recurring-invoices">♻️ Återkommande</router-link></li>
            <li>
              <a href="https://groups.google.com/a/posh.se/g/invoice"
                 target="_blank" rel="noopener noreferrer">🧹 Att betala</a>
            </li>
          </ul>
          <button class="plain" @click="logout">Logga ut</button>
        </nav>
        <router-view v-slot="{ Component }">
          <transition name="fade" mode="out-in">
            <component :is="Component"></component>
          </transition>
        </router-view>
      </div>
    </template>
  </main>
</template>

<script>
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signOut,
} from 'firebase/auth';

export default {
  name: 'App',
  data() {
    return {
      provider: new GoogleAuthProvider(),
      auth: getAuth(),
      user: null,
      error: '',
      loading: true,
    };
  },
  beforeMount() {
    this.auth.onAuthStateChanged((user) => {
      if (user) {
        this.user = user;
      } else {
        this.user = null;
      }
      this.loading = false;
    });
  },
  methods: {
    login() {
      signInWithPopup(this.auth, this.provider)
        .then((result) => {
          this.user = result.user;
          console.log(result);
        })
        .catch((error) => {
          this.error = `An error occurred when logging in: ${error}`;
          console.error(error);
        });
    },
    logout() {
      signOut(this.auth)
        .then(() => {
          this.user = null;
        })
        .catch((error) => {
          this.error = `An error occurred when logging out: ${error}`;
          console.error(error);
        });
    },
  },
};
</script>
